import { Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, Subject, takeUntil } from 'rxjs';
import { LocalizedMessages } from '../../../shared/models/i18n.model';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CommonService } from '../../../shared/service/common/common.service';
import { ModalService } from '../../../shared/service/modal/modal.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from '../../../shared/service/cookie/cookie.service';
import { AuthService } from '../../../shared/service/auth/auth.service';
import { langxObj } from './pin-code_lang';
import { SetupPinDto, UserProfile } from '../../../shared/models/user.model';
import { UserService } from '../../../shared/service/user/user.service';
import { OtpApiErrors, OtpChannel, OtpStage } from '../../../shared/models/otp.model';
import { OtpService } from '../../../shared/service/otp/otp.service';
import { AccountService } from '../../../shared/service/home/account/account.service';

export enum PinCodeStage {
  SetupStage,
  SetupConfirmStage,
  VerifyStage,
  OtpSelectionStage,
  ForgotConfirmStage,
  ChangeStage,
  ChangeConfirmStage,
  OtpVerification,
}

@Component({
  selector: 'app-pin-code',
  templateUrl: './pin-code.component.html',
  styleUrl: './pin-code.component.scss'
})
export class PinCodeComponent implements OnDestroy, OnInit {

  destroy$ = new Subject<void>();
  currentLanguage: string = '';
  langx: LocalizedMessages = {};
  showPasswordText: boolean = false;
  isSetupRoute: boolean = false;
  isForgotRoute: boolean = false;
  isRegistrationSetupRoute: boolean = false;
  isWithWellbetSetupRoute: boolean = false;
  shouldDisplayPinCodeForm: boolean = false;
  shouldDisplayForgotLink: boolean = false;
  shouldShowPinCodeErrorMessage: boolean = false;
  pinCodeForm!: FormGroup;
  mediaServer: string = '';
  pinCode: string = '';
  confirmPinCode: string = '';
  title: string = '';
  description: string = '';
  nextButtonText: string = '';
  otpValidationToken: string = '';
  pinCodeErrorMessage: string = '';
  selectedOtpChannel: OtpChannel = 'mobile';
  stage: number = PinCodeStage.SetupStage;
  pinCodeInputFieldFocusSignals$!: any[];
  timeRemaining$ = new Observable<number>();

  constructor(
    private commonService: CommonService,
    private formBuilder: FormBuilder,
    private modalService: ModalService,
    private router: Router,
    private route: ActivatedRoute,
    private cookieService: CookieService,
    private authService: AuthService,
    private userService: UserService,
    private otpService: OtpService,
    private accountService: AccountService
  ) {
  }

  ngOnInit() {
    this.initaliazePinCodeInputFieldFocusSignals();
    this.initializePinCodeValidationForm();

    this.commonService.mediaServer$.subscribe(mediaServer => {
      this.mediaServer = mediaServer;
    });

    this.commonService.currentLanguage$
      .pipe(takeUntil(this.destroy$))
      .subscribe(currentLanguage => {
        this.currentLanguage = currentLanguage;
        this.langx = langxObj[this.currentLanguage];
      });

    this.checkRoute();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  checkRoute() {
    const url = this.router.url;
    if (url.includes('setup')) {
      this.isSetupRoute = true;
      this.switchStage(PinCodeStage.SetupStage);
    } else if (url.includes('registration')) {
      this.isRegistrationSetupRoute = true;
      this.switchStage(PinCodeStage.SetupStage);
    } else if (url.includes('with-wellbet')) {
      this.isWithWellbetSetupRoute = true;
      this.switchStage(PinCodeStage.SetupStage);
    } else if (url.includes('verify')) {
      this.isSetupRoute = false;
      this.switchStage(PinCodeStage.VerifyStage);
    } else if (url.includes('change')) {
      this.isSetupRoute = false;
      this.switchStage(PinCodeStage.OtpSelectionStage);
    }
  }

  private setupProfile(): void {
    this.commonService.showLoading();

    this.accountService.getContactDetails().pipe(takeUntil(this.destroy$)).subscribe(response => {

      let mobileChannelEnabled = false;
      let emailChannelEnabled = false;
      const incompleteMessage = this.isForgotRoute ? 
        this.langx['PIN_CODE_FORGOT_INCOMPLETE_USER_DETAILS'] : this.langx['PIN_CODE_INCOMPLETE_USER_DETAILS'];

      if (response.isSuccess) {
        const profile = response?.data;
        if(profile) {
          if(profile?.emailVerified && profile?.mobileVerified) {
            mobileChannelEnabled = true;
            emailChannelEnabled = true;

          } else if(!profile?.emailVerified && !profile?.mobileVerified) {
            mobileChannelEnabled = profile?.mobile ? true : false;
            emailChannelEnabled = profile?.email ? true : false;

          } else if(profile?.emailVerified && !profile?.mobileVerified) {
            mobileChannelEnabled = false;
            emailChannelEnabled = true;

          } else if(!profile?.emailVerified && profile?.mobileVerified) {
            mobileChannelEnabled = true;
            emailChannelEnabled = false;
          }
        }

        if(!profile?.mobile && !profile?.email) {
          this.modalService.showError(incompleteMessage);
          this.commonService.emitBackButtonClickSignal();

        } else {
          this.otpService.startWithChannelSelection({
            email: profile.email ?? '',
            emailEnabled: emailChannelEnabled,
            mobile: profile.mobile ?? '',
            mobileEnabled: mobileChannelEnabled
          });
        }
      } else {
        if (response.hasErrors) {
          this.modalService.showError(incompleteMessage);
          this.commonService.emitBackButtonClickSignal();
        }
      }

      this.commonService.hideLoading();
    });
  }

  initaliazePinCodeInputFieldFocusSignals() {
    this.pinCodeInputFieldFocusSignals$ = [
      new BehaviorSubject<boolean>(true),
      new BehaviorSubject<boolean>(false),
      new BehaviorSubject<boolean>(false),
      new BehaviorSubject<boolean>(false),
      new BehaviorSubject<boolean>(false),
      new BehaviorSubject<boolean>(false)
    ];
  }

  initializePinCodeValidationForm() {
    this.pinCodeForm = this.formBuilder.group({});
    this.pinCodeInputFieldFocusSignals$.forEach((item, index) => {
      const control = new FormControl('', [Validators.required, Validators.pattern('^[0-9]{1}$')])
      this.pinCodeForm.addControl(`pinCodeControl${index}`, control);
    });
    this.timeRemaining$ = new Observable<number>();
  }

  onPinCodeFieldInputInIndex($event: Event, index: number) {
    const targetInputValue = ($event.target as HTMLInputElement).value;
    if (targetInputValue.match(/^[0-9]{1}$/g) && index < (this.pinCodeInputFieldFocusSignals$.length - 1)) {
      this.pinCodeErrorMessage = '';
      this.shouldShowPinCodeErrorMessage = false;
      this.pinCodeInputFieldFocusSignals$[index + 1].next(true);
    }
  }

  onPinCodeFieldKeyUpInIndex($event: KeyboardEvent, index: number) {
    if (!($event.target as HTMLInputElement).value) {
      if ($event.key === 'Backspace' && index > 0) {
        this.pinCodeInputFieldFocusSignals$[index - 1].next(true);
      }
    }
  }

  onPinCodeInputPaste($event: ClipboardEvent, index: number) {
    if (index <= (this.pinCodeInputFieldFocusSignals$.length - 1)) {
      const formControls = Object.keys(this.pinCodeForm.controls).slice(index); //get all controls start @ index
      const values = $event.clipboardData?.getData('text').split('') ?? [];

      for (let i = 0; i < formControls.length; i++) {
        if (index < this.pinCodeInputFieldFocusSignals$.length - 1) {
          this.pinCodeInputFieldFocusSignals$[index].next(true);
          index++;
        }
        this.pinCodeForm.get(formControls[i])?.setValue(values[i]);
      }
    }
  }

  isValidTokens(): boolean {
    const otpBypassToken = this.authService.getOtpBypassToken() || '';
    const otpValidationToken = this.otpValidationToken || '';
    return otpBypassToken !== '' || otpValidationToken !== '';
  }

  onPinCodeSubmit(): void {
    const pinCodeValues = Object.keys(this.pinCodeForm.controls)
      .map(controlName => this.pinCodeForm.get(controlName)?.value)
      .join('');

    switch(this.stage) {
      case PinCodeStage.ChangeStage: {
        this.pinCode = pinCodeValues;
        this.switchStage(PinCodeStage.ChangeConfirmStage);
        break;
      }

      case PinCodeStage.SetupStage: {
        this.pinCode = pinCodeValues;
        this.switchStage(PinCodeStage.SetupConfirmStage);
        break;
      }

      case PinCodeStage.ChangeConfirmStage: {
        if (this.pinCode === pinCodeValues) {

          if (!this.isValidTokens()) {
            this.modalService.showError(this.langx['PIN_CODE_EXPIRED'])
              .subscribe(() => {
                if(this.isForgotRoute){
                  this.router.navigate(['../../login'], { relativeTo: this.route });
                } else {
                  this.router.navigate(['../../../home/account'], { relativeTo: this.route });
                }
              });
            return;
          }
  
          this.confirmPinCode = pinCodeValues;
          this.changePin(this.generateDto());
        } else {
          this.pinCodeForm.reset();
          this.pinCodeErrorMessage = this.langx['PIN_CODE_NOT_MATCHED']
          this.shouldShowPinCodeErrorMessage = true;
          this.pinCodeInputFieldFocusSignals$[0].next(true);
        }
        break;
      }

      case PinCodeStage.SetupConfirmStage: {
        if (this.pinCode === pinCodeValues) {

          if (!this.isValidTokens()) {
            this.modalService.showError(this.langx['PIN_CODE_EXPIRED'])
              .subscribe(() => {
                this.router.navigate(['../../login'], { relativeTo: this.route });
              });
            return;
          }
  
          this.confirmPinCode = pinCodeValues;
          this.setupPin(this.generateDto());
        } else {
          this.pinCodeForm.reset();
          this.pinCodeErrorMessage = this.langx['PIN_CODE_NOT_MATCHED'];
          this.shouldShowPinCodeErrorMessage = true;
          this.pinCodeInputFieldFocusSignals$[0].next(true);
        }
        break;
      }

      case PinCodeStage.VerifyStage: {
        this.pinCode = pinCodeValues;
        this.confirmPinCode = pinCodeValues;
        this.verifyPin(this.generateDto());
        break;
      }
    }
  }

  generateDto(): SetupPinDto {
    switch(this.stage) {
      case PinCodeStage.VerifyStage: 
        return {
          pin: this.pinCode
        };
    }
    return {
      pin: this.pinCode,
      confirmPin: this.confirmPinCode,
      otpValidationToken: this.otpValidationToken || '',
      otpBypassToken: this.otpValidationToken? '' : this.authService.getOtpBypassToken()
    };
  }

  setupPin(setupPinDto: SetupPinDto): void {
    this.commonService.showLoading();
    this.userService.setupPinCode(setupPinDto).subscribe(response => {

      if (response.isSuccess) {
        this.modalService.showSuccess(
          this.langx['PIN_CODE_SETUP_SUCCESS_TEXT'],
          this.langx['PIN_CODE_CONTINUE']).subscribe(() => {
          this.proceedToHomePage(response);
        });
      } else {
        if (response.hasErrors) {
          this.modalService.showError(response!.errors![0].message)
            .subscribe(() => {
              this.switchStage(PinCodeStage.SetupStage);
            });
        }
      }

      this.commonService.hideLoading();
    });
  }

  changePin(setupPinDto: SetupPinDto): void {
    this.commonService.showLoading();
    this.userService.setupPinCode(setupPinDto).subscribe(response => {

      if (response.isSuccess) {
        this.modalService.showSuccess(this.langx['PIN_CODE_CHANGE_SUCCESS_TEXT']).subscribe(() => {
          this.authService.deleteAccessToken();
          this.router.navigate(['../../login'], { relativeTo: this.route });
        });
      } else {
        if (response.errors) {
          if(response.errors[0].code === 'error.pin.already-exists'){
            this.pinCodeForm.reset();
            this.pinCodeErrorMessage = response.errors[0].message;
            this.shouldShowPinCodeErrorMessage = true;
            this.pinCodeInputFieldFocusSignals$[0].next(true);
          } else if(response.errors[0].code === 'error.update-pin.request-expired'){
            this.modalService.showError(response.errors[0].message)
              .subscribe(() => {
                if(this.isForgotRoute){
                  this.router.navigate(['../../login'], { relativeTo: this.route });
                } else {
                  this.router.navigate(['../../../home/account'], { relativeTo: this.route });
                }
              });
          } else {
            this.modalService.showError(response.errors[0].message)
              .subscribe(() => {
                this.switchStage(PinCodeStage.ChangeStage);
              });
          }
        }
      }

      this.commonService.hideLoading();
    });
  }

  verifyPin(setupPinDto: SetupPinDto): void {
    this.commonService.showLoading();
    this.userService.verifyPinCode(setupPinDto).subscribe(response => {

      if (response.isSuccess) {
        this.modalService.showSuccess(
          this.langx['PIN_CODE_VERIFY_SUCCESS_TEXT'], 
          this.langx['PIN_CODE_CONTINUE']).subscribe(() => {
          this.proceedToHomePage(response);
        });
      } else {
        if (response.hasErrors) {
          this.switchStage(PinCodeStage.VerifyStage);
          this.modalService.showError(response!.errors![0].message)
        }
      }

      this.commonService.hideLoading();
    });
  }

  proceedToHomePage(response: any): void {
    this.authService.saveHiggsJwt(response.data?.higgsJwt || '');
    this.authService.saveAccessToken(response.data?.accessToken || '');
    this.authService.deleteOtpBypassToken();

    this.cookieService.setLoginCaptchaToken(response.data?.captchaToken ?? '');
    this.userService.setCurrentUser(response.data as UserProfile);
    this.userService.syncPlayerInfo();

    localStorage.setItem('thirdPartyIdentifyId', response.data?.thirdPartyIdentifyId ?? '');

    this.router.navigate(['../../home'], { relativeTo: this.route });
    this.modalService.showConfirmation(this.langx['PIN_CODE_LOGIN_SUCCESS_MESSAGE'],
      this.langx['PIN_CODE_LOGIN_SUCCESS_GOTO_WB'], this.langx['PIN_CODE_LOGIN_SUCCESS_SKIP']).subscribe((gotoWB) => {
      if (gotoWB) {
        this.redirectToWellBet();
      }
    });
  }

  redirectToWellBet(): void {
    this.commonService.getWellBetUrl().subscribe((responseUrl) => {
      window.location.href = responseUrl.data.wellbetUrl;
    });
  }

  switchStage(stage: PinCodeStage) {
    this.stage = stage;

    this.pinCodeForm.reset();
    this.pinCodeInputFieldFocusSignals$[0].next(true);
    this.pinCodeErrorMessage = '';
    this.shouldShowPinCodeErrorMessage = false;

    switch (this.stage) {
      case PinCodeStage.ChangeStage: {
        this.shouldDisplayPinCodeForm = true;
        this.shouldDisplayForgotLink = false;
        this.title = this.langx['PIN_CODE_CHANGE_TEXT'];
        this.description = this.langx['PIN_CODE_CHANGE_DESCRIPTION'];
        this.nextButtonText = this.langx['PIN_CODE_NEXT_TEXT'];

        this.commonService.showBackButtonUntil(this.destroy$, true)
          .subscribe(() => {
            this.switchStage(PinCodeStage.OtpVerification);
          });
        break;
      }

      case PinCodeStage.SetupStage: {
        this.shouldDisplayPinCodeForm = true;
        this.shouldDisplayForgotLink = false;
        this.title = this.langx['PIN_CODE_SETUP_TITLE'];
        this.description = this.langx['PIN_CODE_SETUP_DESCRIPTION'];
        this.nextButtonText = this.langx['PIN_CODE_NEXT_TEXT'];

        this.commonService.showBackButtonUntil(this.destroy$, true)
          .subscribe(() => {
            if(this.isSetupRoute) {
              this.router.navigate(['../../login'], { relativeTo: this.route });
            } else if(this.isRegistrationSetupRoute) {
              this.router.navigate(['../../register'], { relativeTo: this.route });
            } else if(this.isWithWellbetSetupRoute) {
              this.router.navigate(['../../register/with-wellbet'], { relativeTo: this.route });
            }
          });
        break;
      }

      case PinCodeStage.ChangeConfirmStage: {
        this.shouldDisplayPinCodeForm = true;
        this.shouldDisplayForgotLink = false;
        this.title = this.langx['PIN_CODE_CHANGE_CONFIRM_TEXT'];
        this.description = this.langx['PIN_CODE_CHANGE_CONFIRM_DESCRIPTION'];
        this.nextButtonText = this.langx['PIN_CODE_SUBMIT_TEXT'];

        this.commonService.showBackButtonUntil(this.destroy$, true)
          .subscribe(() => {
            this.switchStage(PinCodeStage.ChangeStage);
          });
        break;
      }

      case PinCodeStage.SetupConfirmStage: {
        this.shouldDisplayPinCodeForm = true;
        this.shouldDisplayForgotLink = false;
        this.title = this.langx['PIN_CODE_CHANGE_CONFIRM_TEXT'];
        this.description = this.langx['PIN_CODE_CHANGE_CONFIRM_DESCRIPTION'];
        this.nextButtonText = this.langx['PIN_CODE_SUBMIT_TEXT'];

        this.commonService.showBackButtonUntil(this.destroy$, true)
          .subscribe(() => {
            this.switchStage(PinCodeStage.SetupStage);
          });
        break;
      }

      case PinCodeStage.VerifyStage: {
        this.shouldDisplayPinCodeForm = true;
        this.shouldDisplayForgotLink = true;
        this.title = this.langx['PIN_CODE_VERIFY_TITLE'];
        this.description = this.langx['PIN_CODE_VERIFY_DESCRIPTION'];
        this.nextButtonText = this.langx['PIN_CODE_NEXT_TEXT'];

        this.commonService.showBackButtonUntil(this.destroy$, true)
          .subscribe(() => {
            this.router.navigate(['../../login'], { relativeTo: this.route });
          });
        break;
      }

      case PinCodeStage.OtpVerification: {
        this.shouldDisplayPinCodeForm = false;
        this.shouldDisplayForgotLink = false;

        this.onOtpChannelSelected(this.selectedOtpChannel);
        this.otpService.switchStage(OtpStage.OTP_VALIDATION);
        this.otpService.showComponent();

        this.commonService.showBackButtonUntil(this.destroy$, true)
          .subscribe(() => {
            this.switchStage(PinCodeStage.OtpSelectionStage);
          });
        break;
      }

      case PinCodeStage.OtpSelectionStage: {
        this.shouldDisplayPinCodeForm = false;
        this.shouldDisplayForgotLink = false;

        if(this.isForgotRoute){
          this.title = this.langx['PIN_CODE_FORGOT_TEXT'];
          this.description = this.langx['PIN_CODE_FORGOT_DESCRIPTION'];
        } else {
          this.title = this.langx['PIN_CODE_CHANGE_TEXT'];
          this.description = this.langx['PIN_CODE_SELECT_CHANNEL_DESCRIPTION'];
        }

        this.nextButtonText = this.langx['PIN_CODE_NEXT_TEXT'];
        this.setupProfile();
        this.otpService.switchStage(OtpStage.CHANNEL_SELECTION);
        this.otpService.showComponent();

        this.commonService.showBackButtonUntil(this.destroy$, true)
          .subscribe(() => {
            if(this.isForgotRoute){
              this.otpService.hideComponent();
              this.switchStage(PinCodeStage.VerifyStage);
            } else {
              this.router.navigate(['../../../home/account'], { relativeTo: this.route });
            }
          });
        break;
      }
    }
  }

  onOtpValidationSuccess(otpValidationToken: string) {
    this.switchStage(PinCodeStage.ChangeStage);
    this.otpValidationToken = otpValidationToken;
  }

  onOtpValidationError(errorCode: string) {
    if (errorCode == OtpApiErrors.MAX_RETRIES_REACHED) {
      this.commonService.emitBackButtonClickSignal();
    }
  }

  onOtpValidationSendOtpError(errorCode: string) {
    if (errorCode == OtpApiErrors.MAX_RETRIES_REACHED) {
      this.commonService.emitBackButtonClickSignal();
    }
  }

  onOtpChannelSelected(otpChannel: OtpChannel) {
    this.selectedOtpChannel = otpChannel;
    this.commonService.showBackButtonUntil(this.destroy$, true)
      .subscribe(() => {
        this.switchStage(PinCodeStage.OtpSelectionStage);
      });
    if (otpChannel == 'mobile') {
      this.description = this.langx['PIN_CODE_OTP_VERIFICATION_SMS'];
    } else {
      this.description = this.langx['PIN_CODE_OTP_VERIFICATION_EMAIL'];
    }
  }

  onForgotLinkClick(): void {
    this.isForgotRoute = true;
    this.switchStage(PinCodeStage.OtpSelectionStage);
  }
}
