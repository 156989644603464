import { LocalizedMessagesBundle } from '../../../shared/models/i18n.model';

export const langxObj: LocalizedMessagesBundle = {
  'zh-cn': {
    'PIN_CODE_SETUP_TITLE': '建立安全码',
    'PIN_CODE_SETUP_DESCRIPTION': '请您建立6位数的安全码',
    'PIN_CODE_CONFIRM_TITLE': '请再次输入您的安全码',
    'PIN_CODE_CONFIRM_DESCRIPTION': '请您建立安全码，以便加强您的账户安全',
    'PIN_CODE_VERIFY_TITLE': '请输入您的安全码',
    'PIN_CODE_VERIFY_DESCRIPTION': '输入您的6位的安全码',
    'PIN_CODE_FORGOT_TEXT': '更改安全码',
    'PIN_CODE_FORGOT_DESCRIPTION': '请您选择验证码收取方式。',
    'PIN_CODE_CHANGE_TEXT': '更改安全码',
    'PIN_CODE_CHANGE_DESCRIPTION': '请您更新您的安全码，并确保与旧安全码不同，以确保您的账户安全。',
    'PIN_CODE_CHANGE_CONFIRM_TEXT': '请再次输入您的安全码',
    'PIN_CODE_CHANGE_CONFIRM_DESCRIPTION': '请再次输入与上次相同的安全码。',
    'PIN_CODE_SELECT_CHANNEL_DESCRIPTION': '请选择验证个人信息方式',
    'PIN_CODE_SUBMIT_TEXT': '提交',
    'PIN_CODE_NEXT_TEXT': '下一步',
    'PIN_CODE_TRY_AGAIN': '重试',
    'PIN_CODE_CONTINUE': '继续',
    'PIN_CODE_FORGOT_LINK': '忘记安全码?',
    'PIN_CODE_NOT_MATCHED': '安全码与上次输入不同',
    'PIN_CODE_VERIFY_SUCCESS_TEXT': '安全码成功验证',
    'PIN_CODE_SETUP_SUCCESS_TEXT': '您已成功设置安全码',
    'PIN_CODE_CHANGE_SUCCESS_TEXT': '您的安全码已成功更新，请您使用新的安全码登录。',
    'PIN_CODE_LOGIN_SUCCESS_MESSAGE': '投注功能正在快马加鞭的赶来，敬请期待！可先移步至吉祥坊主站进行投注。',
    'PIN_CODE_LOGIN_SUCCESS_GOTO_WB': '现在就去',
    'PIN_CODE_LOGIN_SUCCESS_SKIP': '略过',
    'PIN_CODE_INCOMPLETE_USER_DETAILS': '请先至用户中心完成您的个人信息以便更改安全码。',
    'PIN_CODE_FORGOT_INCOMPLETE_USER_DETAILS': '目前无可使用的验证方式，请联系在线客服进行协助。',
    'PIN_CODE_OTP_VERIFICATION_SMS': '请输入收到的验证码。',
    'PIN_CODE_OTP_VERIFICATION_EMAIL': '请输入收到的验证码。',
    'PIN_CODE_EXPIRED': '安全码要求已过期'
  },
  'en-gb': {
    'PIN_CODE_SETUP_TITLE': 'Create PIN Code',
    'PIN_CODE_SETUP_DESCRIPTION': 'Please create a 6-digit pin code',
    'PIN_CODE_CONFIRM_TITLE': 'Confirm PIN Code',
    'PIN_CODE_CONFIRM_DESCRIPTION': 'Re-type the code you created',
    'PIN_CODE_VERIFY_TITLE': 'Enter PIN Code',
    'PIN_CODE_VERIFY_DESCRIPTION': 'Enter your 6-digit PIN',
    'PIN_CODE_FORGOT_TEXT': 'Change PIN Code',
    'PIN_CODE_FORGOT_DESCRIPTION': 'Select which contact details should we use to change your PIN code',
    'PIN_CODE_CHANGE_TEXT': 'Change PIN Code',
    'PIN_CODE_CHANGE_DESCRIPTION': 'Create a new PIN Ensure it differs from previous ones for security.',
    'PIN_CODE_CHANGE_CONFIRM_TEXT': 'Confirm PIN Code',
    'PIN_CODE_CHANGE_CONFIRM_DESCRIPTION': 'Re-type the code you created',
    'PIN_CODE_SELECT_CHANNEL_DESCRIPTION': 'Select which contact details should we use to change your PIN code',
    'PIN_CODE_SUBMIT_TEXT': 'Submit',
    'PIN_CODE_NEXT_TEXT': 'Next',
    'PIN_CODE_TRY_AGAIN': 'Try Again',
    'PIN_CODE_CONTINUE': 'Continue',
    'PIN_CODE_FORGOT_LINK': 'Forgot PIN?',
    'PIN_CODE_NOT_MATCHED': 'Change pin and confirm pin do not match.',
    'PIN_CODE_VERIFY_SUCCESS_TEXT': 'You have successfully entered your PIN',
    'PIN_CODE_SETUP_SUCCESS_TEXT': 'You have successfully created your pin',
    'PIN_CODE_CHANGE_SUCCESS_TEXT': 'PIN successfully updated. You can now login again using your new PIN.',
    // eslint-disable-next-line max-len
    'PIN_CODE_LOGIN_SUCCESS_MESSAGE': 'The betting function will be available soon. For now, you may place your bets on the WB website.',
    'PIN_CODE_LOGIN_SUCCESS_GOTO_WB': 'Go to WB',
    'PIN_CODE_LOGIN_SUCCESS_SKIP': 'Ignore',
    // eslint-disable-next-line max-len
    'PIN_CODE_INCOMPLETE_USER_DETAILS': 'Please go to the user center to complete your personal information in order to change your password.',
    // eslint-disable-next-line max-len
    'PIN_CODE_FORGOT_INCOMPLETE_USER_DETAILS': 'There is currently no verification method available, please contact online customer service for assistance.',
    'PIN_CODE_OTP_VERIFICATION_SMS': 'Enter the OTP sent to your mobile number',
    'PIN_CODE_OTP_VERIFICATION_EMAIL': 'Enter the OTP sent to your email address',
    'PIN_CODE_EXPIRED': 'Change PIN has expired already.'
  }
}