import { LocalizedMessagesBundle } from '../../../shared/models/i18n.model';

export const langxObj: LocalizedMessagesBundle = {
  'zh-cn': {
    'REGISTER_TITLE': '创建账号',
    'REGISTER_DESCRIPTION': '创建账号以登入平台',
    'REGISTER_LOGIN_NAME_PLACEHOLDER': '请输入用户名',
    'REGISTER_LOGINNAME_ALREADY_EXISTS': '户名已经被使用了。',
    'REGISTER_LOGINNAME_EMPTY_FIELD': '用户名不能为空。',
    'REGISTER_LOGINNAME_FORMAT_INVALID': '【6到14个字母数字字符或下划线】允许 _ 字符。',
    'REGISTER_LOGINNAME_AVAILABLE': '用户名可以使用',
    'REGISTER_PASSWORD_EMPTY_FIELD': '密码不能为空。',
    'REGISTER_PASSWORD_PLACEHOLDER': '请输入密码',
    'REGISTER_PASSWORD_FORMAT_INVALID': '密码格式不正确。',
    'REGISTER_PASSWORD_NOT_MATCHED': '密码不匹配',
    'REGISTER_CONFIRM_PASSWORD_EMPTY_FIELD': '确认密码不能为空。',
    'REGISTER_CONFIRM_PASSWORD_PLACEHOLDER': '请输入确认密码',
    'REGISTER_CONFIRM_PASSWORD_NOT_MATCHED': '密码不匹配',
    'REGISTER_CONFIRM_PASSWORD_MATCHED': '密码匹配成功',
    'REGISTER_TERMS_TEXT': '注册代表您同意我们的',
    'REGISTER_TERMS_TEXT2': '',
    'REGISTER_TERMS_LINK': '服务协议 和 隐私条款',
    'REGISTER_TERMS_LINK2': '',
    'REGISTER_SUBMIT_TEXT': '注册',
    'REGISTER_SUCCESSFUL_TEXT': '您已成功注册账户',
    'REGISTER_LOGIN_TEXT': '已有账号? ',
    'REGISTER_LOGIN_LINK': '登录',
    'TERMS_TITLE': '条款和条件',
    'TERMS_INTRODUCTION': '请仔细阅读条款和条件，并确保您完全理解其中的内容。如果对本条款所产生的权利和义务有任何疑问，请向您所在法律管辖区的法律顾问寻求帮助。',
    'TERMS_DEFINITION_TITLE': '定义',
    // eslint-disable-next-line max-len
    'TERMS_DEFINITION': '以下规则适用于www.WELLBET.com商业网站提供的WELLBET（以下简称WELLBET、“我们”和“我们的”，视具体情况而定）的在线投注服务的用户、耦合和参与。在游戏和投注规则中具体规定，适用于游戏软件的使用、游戏网站的连接，并包含结合阅读的规则。我们有权通过网站向相关第三方支付平台服务商和金融保险机构提供必要的个人信息，以完成支付请求。本条款和条件中的“投注”或“投注”包括但不限于与网站上提供的任何和/或所有服务相关的投注、游戏和赌博; “应用程序”是指安装在运行 Apple iOS 和 Android OS 操作系统的设备上的软件（包括不时的任何升级）。 “设备”是指任何应用程序访问设备，包括但不限于个人电脑、笔记本电脑、移动电话、个人数字助理、PDA 电话、移动设备以及用于使用和访问的 Apple iOS 和 Android 操作系统设备网站和参与服务。 “软件”是指任何软件、计算机程序、数据文件或任何其他内容（包括与前述内容相关的任何用户信息），包括由 WELLBET 提供且需要安装在您的设备上以便您能够使用的任何应用程序，通过您的设备访问并参与服务。',
    'TERMS_ACCEPT_TEXT': '同意',
    'REGISTER_CREATE_PIN_CODE': '您已成功注册账户。请您创建您的安全码，以确保您的账户安全。'
  },
  'en-gb': {
    'REGISTER_TITLE': 'Create an Account',
    'REGISTER_DESCRIPTION': 'Complete your profile by creating an account',
    'REGISTER_LOGIN_NAME_PLACEHOLDER': 'Username',
    'REGISTER_LOGINNAME_ALREADY_EXISTS': 'Username already exists',
    'REGISTER_LOGINNAME_EMPTY_FIELD': 'Username can’t be empty.',
    // eslint-disable-next-line max-len
    'REGISTER_LOGINNAME_FORMAT_INVALID': '【6 to 14 alphanumeric characters or underscores】. Allowed _ characters.',
    'REGISTER_LOGINNAME_AVAILABLE': 'Username can be used.',
    'REGISTER_PASSWORD_EMPTY_FIELD': 'Password field can’t be empty.',
    'REGISTER_PASSWORD_PLACEHOLDER': 'Password',
    'REGISTER_PASSWORD_FORMAT_INVALID': 'Password format incorrect.',
    'REGISTER_PASSWORD_NOT_MATCHED': 'Passwords do not match.',
    'REGISTER_CONFIRM_PASSWORD_EMPTY_FIELD': 'Confirm password can’t be empty.',
    'REGISTER_CONFIRM_PASSWORD_PLACEHOLDER': 'Confirm Password',
    'REGISTER_CONFIRM_PASSWORD_NOT_MATCHED': 'Passwords do not match.',
    'REGISTER_CONFIRM_PASSWORD_MATCHED': 'Passwords successfully matched.',
    'REGISTER_TERMS_TEXT': 'By signing up you are confirming that you agreed to our',
    'REGISTER_TERMS_LINK': 'Terms of use and privacy policy',
    'REGISTER_TERMS_TEXT2': '',
    'REGISTER_TERMS_LINK2': '',
    'REGISTER_SUBMIT_TEXT': 'Sign Up',
    'REGISTER_SUCCESSFUL_TEXT': 'You have successfully registered an account.',
    'REGISTER_LOGIN_TEXT': 'Already have an Account? ',
    'REGISTER_LOGIN_LINK': 'Login',
    'TERMS_TITLE': 'Terms and Conditions',
    'TERMS_ACCEPT_TEXT': 'Agree',
    // eslint-disable-next-line max-len
    'REGISTER_CREATE_PIN_CODE': 'You have successfully registered an account. Create PIN to make your account more secure.'
  }
}
