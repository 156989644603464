import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ComponentModule } from '../../../shared/component/component.module';
import { PinCodeComponent } from './pin-code.component';
import { DirectiveModule } from '../../../shared/directive/directive.module';



@NgModule({
  declarations: [
    PinCodeComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    ComponentModule,
    ReactiveFormsModule,
    DirectiveModule
  ],
  exports: [
    PinCodeComponent
  ]
})
export class PinCodeModule { }