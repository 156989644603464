import { LocalizedMessagesBundle } from '../../../shared/models/i18n.model';

export const langxObj: LocalizedMessagesBundle = {
  'zh-cn': {
    'LOGIN_TITLE': '登录',
    'LOGIN_DESCRIPTION': '登录以管理您的账户',
    'LOGIN_REMEMBER_TEXT': '记住账号',
    'LOGIN_FORGOT_PASSWORD_LINK': '忘记密码？',
    'LOGIN_SUBMIT_TEXT': '登录',
    'LOGIN_DONT_HAVE_ACCOUNT_YET' : '我是第一次登录?',
    'LOGIN_REGISTER_LINK': '免费注册',
    'LOGIN_NAME_PLACEHOLDER': '请输入用户名',
    'LOGIN_PASSWORD_PLACEHOLDER': '请输入密码',
    'LOGIN_REGISTER_WITH_TEXT': '以WB账号注册',
    'LOGIN_USERNAME_CANNOT_BE_EMPTY': '用户名不能为空。',
    'LOGIN_PASSWORD_CANNOT_BE_EMPTY': '密码不能为空。',
    'LOGIN_INVALID_CREDENTIALS': '用户名或者密码有误，请重新输入！',
    'LOGIN_SUCCESS_MESSAGE': '投注功能正在快马加鞭的赶来，敬请期待！可先移步至吉祥坊主站进行投注。',
    'LOGIN_SUCCESS_GOTO_WB': '现在就去',
    'LOGIN_SUCCESS_GOTO_SKIP': '略过',
    'LOGIN_CREATE_PIN_CODE': '请您创建您的安全码，以确保您的账户安全'
  },
  'en-gb': {
    'LOGIN_TITLE': 'Login',
    'LOGIN_DESCRIPTION': 'Sign in to manage your account',
    'LOGIN_REMEMBER_TEXT': 'Remember Me',
    'LOGIN_FORGOT_PASSWORD_LINK': 'Forgot Password?',
    'LOGIN_SUBMIT_TEXT': 'Login',
    'LOGIN_DONT_HAVE_ACCOUNT_YET' : 'Don\'t have any account yet?',
    'LOGIN_REGISTER_LINK': 'Register',
    'LOGIN_NAME_PLACEHOLDER': 'Username',
    'LOGIN_PASSWORD_PLACEHOLDER': 'Password',
    'LOGIN_REGISTER_WITH_TEXT': 'Or Register with',
    'LOGIN_USERNAME_CANNOT_BE_EMPTY': 'Username cannot be empty.',
    'LOGIN_PASSWORD_CANNOT_BE_EMPTY': 'Password field can\'t be empty.',
    'LOGIN_INVALID_CREDENTIALS': 'Invalid credentials. Please try again.',
    'LOGIN_SUCCESS_MESSAGE': '投注功能正在快马加鞭的赶来，敬请期待！可先移步至吉祥坊主站进行投注。',
    'LOGIN_SUCCESS_GOTO_WB': 'Going right now',
    'LOGIN_SUCCESS_GOTO_SKIP': 'Skip',
    'LOGIN_CREATE_PIN_CODE': 'Create PIN to make your account more secure'
  }
}