import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { RouterModule } from '@angular/router';
import { LoginModule } from './login/login.module';
import { RegisterModule } from './register/register.module';
import { UserRoutingModule } from './user-routing.module';
import { UserComponent } from './user.component';
import { RegisterWithWellbetModule } from './register-with-wellbet/register-with-wellbet.module';
import { ForgotPasswordModule } from './forgot-password/forgot-password.module';
import { PinCodeModule } from './pin-code/pin-code.module';


@NgModule({
  declarations: [
    UserComponent,
  ],
  imports: [
    CommonModule,
    UserRoutingModule,
    RouterModule,
    RegisterModule,
    LoginModule,
    RegisterWithWellbetModule,
    ForgotPasswordModule,
    PinCodeModule
  ],
  exports: [
    UserComponent
  ]
})
export class UserModule { }
