<div class="pin-code-wrapper">
    <div class="pin-code-form-wrapper">
        <h1 class="pin-code-form-title text header text-center">
            {{ title }}
        </h1>
        <p class="pin-code-form-description text-small text-center">
            {{ description }}
        </p>

        @if(shouldDisplayPinCodeForm) {
            <form [formGroup]="pinCodeForm" (submit)="onPinCodeSubmit()">
                <div class="form-group pinCode">
                    <input maxlength="1" type="password" class="text-center form-control" formControlName="pinCodeControl0"
                        [class.filled]="pinCodeForm.get('pinCodeControl0')?.value" inputmode="numeric"
                        (paste)="onPinCodeInputPaste($event, 0)" (input)="onPinCodeFieldInputInIndex($event, 0)" 
                        (keyup)="onPinCodeFieldKeyUpInIndex($event, 0)" [appAutofocusOnEmit]="pinCodeInputFieldFocusSignals$[0]">
                    <input maxlength="1" type="password" class="text-center form-control" formControlName="pinCodeControl1"
                        [class.filled]="pinCodeForm.get('pinCodeControl1')?.value" inputmode="numeric"
                        (paste)="onPinCodeInputPaste($event, 1)" (input)="onPinCodeFieldInputInIndex($event, 1)" 
                        (keyup)="onPinCodeFieldKeyUpInIndex($event, 1)" [appAutofocusOnEmit]="pinCodeInputFieldFocusSignals$[1]">
                    <input maxlength="1" type="password" class="text-center form-control" formControlName="pinCodeControl2"
                        [class.filled]="pinCodeForm.get('pinCodeControl2')?.value" inputmode="numeric"
                        (paste)="onPinCodeInputPaste($event, 2)" (input)="onPinCodeFieldInputInIndex($event, 2)" 
                        (keyup)="onPinCodeFieldKeyUpInIndex($event, 2)" [appAutofocusOnEmit]="pinCodeInputFieldFocusSignals$[2]">
                    <input maxlength="1" type="password" class="text-center form-control" formControlName="pinCodeControl3"
                        [class.filled]="pinCodeForm.get('pinCodeControl3')?.value" inputmode="numeric"
                        (paste)="onPinCodeInputPaste($event, 3)" (input)="onPinCodeFieldInputInIndex($event, 3)" 
                        (keyup)="onPinCodeFieldKeyUpInIndex($event, 3)" [appAutofocusOnEmit]="pinCodeInputFieldFocusSignals$[3]">
                    <input maxlength="1" type="password" class="text-center form-control" formControlName="pinCodeControl4"
                        [class.filled]="pinCodeForm.get('pinCodeControl4')?.value" inputmode="numeric"
                        (paste)="onPinCodeInputPaste($event, 4)" (input)="onPinCodeFieldInputInIndex($event, 4)" 
                        (keyup)="onPinCodeFieldKeyUpInIndex($event, 4)" [appAutofocusOnEmit]="pinCodeInputFieldFocusSignals$[4]">
                    <input maxlength="1" type="password" class="text-center form-control" formControlName="pinCodeControl5"
                        [class.filled]="pinCodeForm.get('pinCodeControl5')?.value" inputmode="numeric"
                        (paste)="onPinCodeInputPaste($event, 5)" (input)="onPinCodeFieldInputInIndex($event, 5)" 
                        (keyup)="onPinCodeFieldKeyUpInIndex($event, 5)" [appAutofocusOnEmit]="pinCodeInputFieldFocusSignals$[5]">
                </div>

                @if (shouldShowPinCodeErrorMessage) {
                    <p class="pin-code-field-error text-small"><span class="material-symbols-outlined">error</span> 
                        {{ pinCodeErrorMessage }}
                    </p>
                }
    
                <div class="form-group submit">
                    <button class="btn btn-primary" [disabled]="pinCodeForm.invalid">
                        {{ nextButtonText }}
                    </button>
                </div>
            </form>
        }

        @if (shouldDisplayForgotLink) {
            <div class="forgot-redirection text-center">
                <a (click)="onForgotLinkClick()" class="link text-very-small">
                    {{ langx['PIN_CODE_FORGOT_LINK'] }}
                </a>
            </div>
        }
    </div>

    <app-otp
        [purpose]="'pin-update'"
        (errorSendOtp)="onOtpValidationSendOtpError($event)"
        (errorOtpValidation)="onOtpValidationError($event)"
        (successOtpValidation)="onOtpValidationSuccess($event)"
        (channelSelected)="onOtpChannelSelected($event)">
    </app-otp>
</div>